<template>
    <div class="container">
    
        <div>
          <img class="mimis grad"  src="../assets/lokal.jpg" >
        <h2 class="white">Varför välja mig som massör?</h2>
        <p class="pre white"> {{ texterna.why.text }}
         </p>
     
     
       
    </div>
    </div>
   
   </template>
   <script>
   import texter from "../assets/texter.json"
       
   export default {
    data(){ 
  return {
    texterna: texter,
  } 
}, 
    
   name: 'HeaDer ',
   }

   
   
   </script>
   <style scoped>
   
   .container{
  
    background-image: url("@/assets/bg_gul.png");    
   
 
    display: flex;
    justify-content: center;
    align-items: center;
   }
  
   .knappar{
    width: 20%;
    
     
   }
   .grad {
      mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0, 0, 0, .5));
    

}
   
   @media only screen and (max-width: 600px){
    
  .container{
    height:auto;
    
  }
p {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}


    

}
   
   </style>
   