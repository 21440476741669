<template>
  <div>
  
   <AttStart />
   <AttWhy />
   <!-- <AttTillsammans /> -->
  
  <!--<AttHM />-->
  <AttInformation />
  <AttHM />
    <AttTraining2 />
    <div id="tid">
      
      <br />
      <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FStockholm&showPrint=0&showCalendars=1&showTabs=1&showTz=0&mode=WEEK&src=Ym5qOTg1a29oYWZyZ2I4ZGY4YW8zODR1Y2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F6BF26" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>    </div>
    

      <!-- ANDLIG/MEDIAL HEALING I GÖTEBORG
BÅDA FÖR EN DJUPARE FOKUSERING PÅ DET INRE, KÄRLEKEN I HJÄRTAT OCH HAR TILLTRO TILL DEN LÄKANDE KRAFT VI HAR TILLGÅNG TILL OM VI BARA UPPMÄRKSAMMAR DEN OCH TILLÅTER DEN.


Mer om andlig & medial healing

Behandling där man kan sitta eller ligga ner. Med kläder på. Terapeuten börjar eller enbart håller lätt på dina axlar. Båda för en djupare fokusering på det inre, kärleken i hjärtat och har tilltro till den läkande kraft vi har tillgång till om vi bara uppmärksammar den och tillåter den.

Ofta infinner sig en djup inre ro, spänningar släpper, känslor och tankar kan forsa förbi för att uppmärksammas och släppas i lugn och ro. Ibland kan mediala meddelanden framträda men ofta fungerar terapeuten som en länkande kanal för subtila energiflöden som intelligent balanserar där det behövs bäst just då. Processen av andlig healing sker kontinuerligt, i den takt som ger bäst effekt för just dig. Det kan starta en mindre förändring i ditt tankemönster som du kommer att fortsätta utveckla. Din tilltro till din egen förmåga att läka och finna ro är viktig i din process. Att anteckna ner fysiska reaktioner, tankar, känslor eller aha-upplevelser kan hjälpa dig att överblicka din egen process. 

30 min kan räcka, 45 min eller längre om mer tid att få vila i processen och samtal känns viktigt. 

Mer om Andlig / Medial Healing

Kan utföra distanshealing när klienten har svårare att ta sig till mig. Jag sitter i djup meditation och känner in och kanaliserar helande inre balansering och stärkande mediala budskap kan framträda. Vi håller kontakt både innan och efter men man måste inte sitta ner samtidigt. Arbetar alltid i kärlek, i själskontakt och för klientens högsta bästa. 200 kr. 
-->
   <AttFot />
  </div>
  
  

</template>

<script>

import AttWhy from '@/components/attWhy.vue';
import AttStart from '../components/attStart.vue';
//import AttTillsammans from '@/components/attTillsammans.vue';
import AttFot from '@/components/attFot.vue';

 
 import AttTraining2 from '@/components/attTraining2.vue';
import AttHM from '@/components/attHM.vue';

 
import AttInformation from '@/components/attInformation.vue';

export default {
  name: 'PrimelevaView', 
 components: { AttStart, AttWhy, AttFot, AttInformation,AttHM, AttTraining2},
 //AttTillsammans,
 data(){ 
  return {
    
  } 
  

},
methods:{
  
}

}

/* 
import NewsPost from '../components/NewsPost.vue';
import blogg from "@/static/json/blogg.json"
  export default {
    components: { NewsPost },
   
    data() {
      return {
      poster: blogg,
      }
    }
} */
</script>
<style scoped>


</style>
