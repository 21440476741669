<template>
    <div class="container" >
      <div class="anka" id="info">x</div>

        <div class="ram">
        <h3>{{ texterna.fotKontakt.rubrik }}</h3>
        <p class="pre"> {{ texterna.fotKontakt.text }}</p>
        <h3>{{ texterna.fotAdress.rubrik }}</h3>
        <p class="pre"> {{ texterna.fotAdress.text }}</p>
        <h3>{{ texterna.fotPriser.rubrik }}</h3>
        <p class="pre"> {{ texterna.fotPriser.text }}</p>
        <h3>{{ texterna.fotKollektiv.rubrik }}</h3>
        <p class="pre"> {{ texterna.fotKollektiv.text }}</p>
     
        <img class="mimis"  src="../assets/paula.jpg" >
        <a href="https://www.facebook.com/primaleva" target="_blank">Facebook</a><br>
        <a href="https://www.instagram.com/primaleva/" target="_blank">Instagram</a><br>
        <a href="http://www.linkedin.com/in/paula-berntsson-36779b1b7" target="_blank">LinkedIn</a>

   
    
   
     
      
    </div>
    </div>
   
   </template>
   <script>
   import texter from "../assets/texter.json"
       
   export default {
    data(){ 
  return {
    texterna: texter,
  } 
}, 
    
   name: 'HeaDer ',
   }

   
   
   </script>
   <style scoped>

.anka{
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
    
   }
   
   .container{
    background-color: rgb(169, 165, 163);   
    height: 800px;
   
  
 
    
   }
   .ram{
    column-gap: 25px;
    column-count: 4;
    margin: 40px;
   }
  
   
   
   .knappar{
    width: 20%;
    
     
   }
   
   @media only screen and (max-width: 600px){
    
  .container{
    height:auto;
    column-count: 1;
    
  }
  h3{
    padding-bottom: 0px;
    margin: 0px;
  }
p {
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}
.ram{
    
    column-count: 1;
    margin: 20px;
   } 

}
   
   </style>
   